import { makeStyles } from "@material-ui/core/styles";
export const useTaxFormStyles = makeStyles(theme => ({
  taxHeader: {
    display: "inline-flex",
    justifyContent: "space-between",
    margin: theme.spacing(2),
    marginBottom: 0
  },
  title: {
    margin: theme.spacing(1)
  },
  addTaxBtn: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  gridTitle: {
    fontWeight: "bold"
  },
  gridTitleContainer: {
    marginLeft: theme.spacing(2)
  },
  headerBreak: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  saveBtnGrp: {
    display: "flex",
    justifyContent: "right",
  },
}));
