import { makeStyles } from "@material-ui/core/styles";

export const useStyle = makeStyles(theme => ({
    fieldLabel:{
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(3),
        fontWeight: "bold"
      },
    percentage: {
        paddingRight: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    description: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        marginTop: theme.spacing(2)
    },
    mobileview: {
        marginTop: theme.spacing(2)
    },
    mobileviewBtns: {
        marginTop: theme.spacing(4),
        marginLeft: theme.spacing(8)
    },
    buttons: {
        marginLeft: theme.spacing(4),
        float:"right",
    },
}))