import React from "react";
import { PropTypes } from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import {
  TextField,
  Grid,
  IconButton,
  Typography,
  ListItem
} from "@material-ui/core";
import { Clear, Reorder, AddCircleOutline } from "@material-ui/icons";
import clsx from "clsx";
import { useStyle } from "./styles";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Draggable } from "react-beautiful-dnd";

const TaxComponent = ({
  parent,
  item,
  parentIndex,
  index,
  className,
  onAddComponent,
  onRemoveComponent
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const theme = useTheme();
  const classes = useStyle();
  const smallMatch = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Draggable draggableId={item.id} index={index}>
      {provided => (
        <ListItem ref={provided.innerRef} {...provided.draggableProps}>
          <Grid
            container
            className={clsx(className, `tax-component-index-${index}`)}
          >
            <Grid
              item
              container
              className={clsx(className)}
              xs={smallMatch ? 12 : 4}
            >
              <Grid item xs={smallMatch ? 5 : 6}>
                <Typography className={smallMatch ? classes.mobileview : classes.fieldLabel}>
                  Tax Percentage:
                </Typography>
              </Grid>
              <Grid item xs={smallMatch ? 5 : 6}>
                <Controller
                  name={`${parent}.percentage`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="percentage"
                      {...field}
                      type="text"
                      className={smallMatch ? classes.mobileview : classes.percentage}
                      error={!!errors?.taxes?.[`${parentIndex}`]?.taxComponents?.[`${index}`]?.percentage}
                      helperText={errors?.taxes?.[`${parentIndex}`]?.taxComponents?.[`${index}`]?.percentage?.['message']}
                      inputProps={{
                        "data-testid": "percentage",
                        "aria-label": "percentage",
                        step: 0.001,
                        maxLength: 6
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              className={clsx(className)}
              xs={smallMatch ? 12 : 4}
            >
              <Grid item xs={smallMatch ? 5 : 4}>
                <Typography className={smallMatch ? classes.mobileview : classes.fieldLabel}>
                  Description:
                </Typography>
              </Grid>
              <Grid item xs={smallMatch ? 5 : 8}>
                <Controller
                  name={`${parent}.description`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="description"
                      className={smallMatch ? classes.mobileview : classes.description}
                      error={!!errors?.taxes?.[`${parentIndex}`]?.taxComponents?.[`${index}`]?.description}
                      helperText={errors?.taxes?.[`${parentIndex}`]?.taxComponents?.[`${index}`]?.description?.['message']}
                      inputProps={{
                        "data-testid": "description",
                        "aria-label": "description"
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              className={smallMatch ? clsx(classes.mobileviewBtns) : clsx(classes.buttons)}
              xs={smallMatch ? 12 : 3}
            >
              <Grid item xs={3}>
                <IconButton
                onClick={onRemoveComponent}
                data-testid={`remove-btn-${index}`}
                className='remove-btn'
                >
                  <Clear />
                </IconButton>
              </Grid>
              <Grid item xs={3}>
                <IconButton
                onClick={onAddComponent}
                data-testid={`add-btn-${index}`}
                className='add-btn'
                >
                  <AddCircleOutline />
                </IconButton>
              </Grid>
              <Grid item xs={3}>
                <IconButton {...provided.dragHandleProps}>
                  <Reorder />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
      )}
    </Draggable>
  );
};

TaxComponent.defaultProps = {
  onAddComponent: undefined,
  onRemoveComponent: () => {}
};

TaxComponent.propTypes = {
  onRemoveComponent: PropTypes.func,
  onAddComponent: PropTypes.func
};

export default TaxComponent;
