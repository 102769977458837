import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { Typography, Button, Grid } from "@material-ui/core";
import { useTaxFormStyles } from "./styles";
import ColoredLine from "../../ColoredLine";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TaxParent from "./TaxParent";
import useHasPermissions from "../../../hooks/useHasPermissions";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import taxesService from "../../../services/TaxesService";
import apiClient from "../../../auth/apiClient";
import { useTheme } from "@material-ui/core/styles";
import { isNull, isUndefined, orderBy } from "lodash";

const taxService = new taxesService(apiClient);

export const taxSchema = Yup.object().shape({
  taxes: Yup.array().of(
    Yup.object().shape({
      taxID: Yup.number(),
      entityID: Yup.string().required(),
      taxName: Yup.string().required("Tax name is required."),
      reverse: Yup.bool(),
      discountAfter: Yup.bool(),
      compound: Yup.bool(),
      taxComponents: Yup.array().of(
        Yup.object().shape({
          order: Yup.number().required(),
          percentage: Yup.number()
            .typeError("Percentage must be a number between 0-100.")
            .min(0, "Must be a positive number.")
            .max(100, "Maximum of 100 percent.")
            .required("Tax percentage is required.")
            .test(
              'percentage-decimal-validator',
              function(value) {
                if (!(value + "").match("^[0-9]{1,6}(?:\.[0-9]{1,3})?$")){
                  return this.createError({
                      message: "Percentage can have a maximum of 3 decimal places.",
                  });
                } else return true;                  
              }),
          description: Yup.string().required("Tax description is required.")
        })
      )
    })
  )
});

const TaxForm = ({ entityID, onClose }) => {
  const classes = useTaxFormStyles();
  const { textConfirmation } = useConfirmationDialog();
  const { hasPermissions } = useHasPermissions();
  const editTaxesPermission = hasPermissions(["rates.edit"]);
  const addTaxesPermission = hasPermissions(["rates.add"]);

  const defaultValues = {
    taxID: 0,
    entityID: entityID,
    taxName: "",
    reverse: true,
    discountAfter: false,
    compound: false,
    taxComponents: [
      {
        order: 0,
        percentage: 0.0,
        description: ""
      }
    ]
  };
  const theme = useTheme();
  const methods = useForm({
    resolver: yupResolver(taxSchema),
    defaultValues: {
      taxes: [defaultValues]
    }
  });
  const { 
    handleSubmit,
    formState: { isSubmitting, errors }
   } = methods;
  const { fields: taxEntries, append, remove, replace } = useFieldArray({
    control: methods.control,
    name: "taxes"
  });

  const fetchTaxInfo = useCallback(
    async entityID => {
      try {
        let response = await taxService.getPageableTaxes(entityID);
        return response.data.collection;
      } catch {
        return defaultValues;
      }
    },
    [entityID]
  );

  useEffect(() => {
    fetchTaxInfo(entityID).then(info => {
      if (isUndefined(info)) replace(defaultValues);
      else replace(orderBy(info, ["taxID"], ["asc"]));
    });
  }, [entityID]);

  const designateComponentOrder = components => {
    for (let i = 0; i < components.length; i++) {
      components[i].order = i + 1;
      components[i].percentage = Number(components[i].percentage);
      console.log(components[i].percentage, typeof components[i].percentage);
    }
  };

  const onTaxSubmit = async values => {
    let taxArray;
    if(isUndefined(values) || isNull(values)){ 
      return; 
    } else {
      taxArray = values.taxes; 
    }
    let createList = [];
    let updateList = [];
    let latestList = [];
    let success = false;

    for (let i = 0; i < taxArray.length; i++) {
      designateComponentOrder(taxArray[i].taxComponents);
      if (taxArray[i].taxID === 0 || isUndefined(taxArray[i].taxID)) {
        taxArray[i].taxID = undefined;
        createList.push(taxArray[i]);
      } else {
        updateList.push(taxArray[i]);
      }
    }

    if (createList.length > 0) {
      for (let i = 0; i < createList.length; i++) {
        console.log(createList[i]);
        try {
          var response = await taxService.createTax(entityID, createList[i]);
          if (response.status === 200) {
            latestList.push(response.data);
            success = true;
          }
        } catch {
          success = false;
        }
      }
    }
    if (updateList.length > 0) {
      for (let i = 0; i < updateList.length; i++) {
        try {
          var response = await taxService.updateTax(entityID, updateList[i]);
          if (response.status === 200) {
            latestList.push(response.data);
            success = true;
          }
        } catch {
          success = false;
        }
      }
    }

    replace(orderBy(latestList, ["taxID"], ["asc"]));
  };

  const handleDeleteTax = async (entityID, taxID, parentIndex) => {
    if (taxID === 0) {
      remove(parentIndex);
      return;
    }
    const confirmed = await textConfirmation({
      title: "Delete Tax Setting",
      message:
        "This tax may be associated with a parking rate. Are you sure you want to delete this tax?",
      textConfirmation: taxEntries[parentIndex].taxName.toUpperCase(),
      buttons: [
        { name: "Yes", color: "secondary" },
        { name: "No", color: theme.palette.text.disabled }
      ]
    });
    if (confirmed) {
      //deleteTaxes takes a list as second parameter
      const taxIDs = [];
      taxIDs.push(taxID);
      try {
        let response = await taxService.deleteTaxes(entityID, taxIDs);
        if (response.status === 200) remove(parentIndex);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleAddTax = () => {
    append(defaultValues);
  };

  return (
    <>
      <Grid container className={classes.taxHeader}>
        <Typography variant="h5" color="primary" className={classes.title}>
          Taxes
        </Typography>
        <Button
          className={classes.addTaxBtn}
          variant="outlined"
          color="primary"
          data-testid="add-btn"
          onClick={handleAddTax}
          disabled={!addTaxesPermission}
        >
          Add
        </Button>
      </Grid>
      <ColoredLine className={classes.headerBreak} />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onTaxSubmit)}>
          {taxEntries.map((field, index) => (
            <TaxParent
              key={field.id}
              index={index}
              {...field}
              entityID={entityID}
              onDelete={() => handleDeleteTax(entityID, field.taxID, index)}
            />
          ))}
          <div className={classes.saveBtnGrp}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              data-testid="save-btn"
              disabled={!editTaxesPermission && !addTaxesPermission || isSubmitting}
            >
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};
TaxForm.propTypes = {
  entityID: PropTypes.string.isRequired
};
export default TaxForm;
