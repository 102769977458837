import { makeStyles } from "@material-ui/core/styles";

export const useTaxParentStyle = makeStyles(theme => ({
  rootContainer: { minWidth: "40vw" },
  gridTitle: {
    fontWeight: "bold"
  },
  gridTitleContainer: {
    marginLeft: theme.spacing(2)
  },
  textfield:{
      marginTop: theme.spacing(2)
  },
  fieldLabel:{
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    marginTop: 5,
    fontWeight: "bold"
  },
  dropDown: {
    marginTop: "10px",
    minWidth: "120px",
    paddingRight: theme.spacing(2)
  },
  taxInfoContainer: {
    margin: theme.spacing(2)
  },
  taxName: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      minWidth: "150px"
    }
  },
  taxComponent: {
    marginLeft: theme.spacing(2)
  },
  sectionBreak: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  delBtn: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(3)
  },
  mobileViewDelBtn: {
    color: "error",
    float: "right",
    marginRight: theme.spacing(2)
  },
  mobileViewFieldLabel:{
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    fontWeight: "bold"
  }
}));