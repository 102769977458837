import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  Button
} from "@material-ui/core";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Delete from "@material-ui/icons/Delete";
import { useTaxParentStyle } from "./styles";
import TaxComponent from "../TaxComponent";
import { Controller, useFormContext, useFieldArray } from "react-hook-form";
import BooleanSelect from "../../BooleanSelect";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useHasPermissions from "../../../../hooks/useHasPermissions";
import { useTheme } from "@material-ui/core/styles";
import ColoredLine from "../../../ColoredLine";
import { isUndefined } from "lodash";
import clsx from "clsx";

const TaxParent = ({ index: parentIndex, onDelete, ...props }) => {
  const classes = useTaxParentStyle();
  const { hasPermissions } = useHasPermissions();
  const deleteTaxesPermission = hasPermissions(["rates.delete"]);
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const { fields: components, append, remove, replace } = useFieldArray({
    control,
    name: `taxes[${parentIndex}].taxComponents`
  });

  const theme = useTheme();
  const smallMatch = useMediaQuery(theme.breakpoints.down("sm"));

  const handleAddComponent = () => {
    append({ 
      percentage: 0, 
      description: "", 
      order: components.length + 1 
    });
  };

  const handleRemoveComponent = id => {
    if (components.length === 1) return;
    remove(id);
  };

  const handleDragEnd = result => {
    if (!result.destination) return;
    const items = components;
    const [reorderedComponent] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedComponent);

    replace(items);
  };

  return (
    <>
      <Grid container direction={smallMatch ? "column" : "row"}>
        <Grid
          className={clsx(classes.taxInfoContainer, `tax-info-${props.taxName ? props.taxName : 'new-tax'}`)}
          container
          direction={smallMatch ? "column" : "row"}
        >
        {smallMatch &&
          <Grid item xs={12}>
            <Button
              className={classes.mobileViewDelBtn}
              variant="contained"
              color="secondary"
              disabled={!deleteTaxesPermission}
              data-testid="del-btn"
              onClick={onDelete}
            >
              Delete
            </Button>
          </Grid>
        }
          <Grid item xs={4} className={classes.taxName}>
            <Typography className={smallMatch ? classes.mobileViewFieldLabel : classes.fieldLabel}>Tax Name</Typography>
            <Controller
              name={`taxes[${parentIndex}].taxName`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.textfield}
                  id="taxName"
                  error={errors?.taxes?.[`${parentIndex}`]?.taxName}
                  helperText={errors?.taxes?.[`${parentIndex}`]?.taxName?.['message']}
                  inputProps={{
                    "data-testid": "taxName",
                    "aria-label": "taxName"
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={2} className={classes.taxName}>
            <Tooltip title="Reverse tax is included in the parking fee. Forward tax is added onto the initial price.">
              <Typography className={classes.fieldLabel}>Type</Typography>
            </Tooltip>
            <Controller
              name={`taxes[${parentIndex}].reverse`}
              control={control}
              render={({ field }) => (
                <BooleanSelect
                  {...field}
                  className={classes.dropDown}
                  values={["Reverse", "Forward"]}
                  name="reverse"
                />
              )}
            />
          </Grid>
          <Grid item xs={2} className={classes.taxName}>
            <Typography className={classes.fieldLabel}>Discount</Typography>
            <Controller
              name={`taxes[${parentIndex}].discountAfter`}
              control={control}
              render={({ field }) => (
                <BooleanSelect
                  {...field}
                  className={classes.dropDown}
                  values={["After", "Before"]}
                  name="discount"
                />
              )}
            />
          </Grid>
          <Grid item xs={2} className={classes.taxName}>
            <Tooltip
              title="A compound tax will be calculated when you add additional taxes to an item on top of the primary tax. 
                          This tax is added on top of your item cost once it has the primary tax already added, 
                          rather than calculated from the untaxed item amount."
            >
              <Typography className={classes.fieldLabel}>Compound</Typography>
            </Tooltip>
            <Controller
              name={`taxes[${parentIndex}].compound`}
              control={control}
              render={({ field }) => (
                <BooleanSelect
                  {...field}
                  className={classes.dropDown}
                  values={["Yes", "No"]}
                  name="compound"
                />
              )}
            />
          </Grid>       
          {!smallMatch &&
            <Grid item xs={1}>
              <IconButton
                className={classes.delBtn}
                disabled={!deleteTaxesPermission}
                data-testid="del-btn"
                onClick={onDelete}
              >
                <Delete />
              </IconButton>
            </Grid>
          }
        </Grid>
      </Grid>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="taxComponents">
          {provided => (
            <div
              className={clsx("taxComponents", `tax-components-${props.taxName ? props.taxName : 'new-tax'}`)}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {components?.map((component, index) => {
                return (
                  <TaxComponent
                    key={component.id}
                    item={component}
                    index={index}
                    className={classes.taxComponent}
                    parent={`taxes[${parentIndex}].taxComponents[${index}]`}
                    parentIndex={parentIndex}
                    {...component}
                    onAddComponent={
                      index == components.length - 1
                        ? handleAddComponent
                        : undefined
                    }
                    onRemoveComponent={() => handleRemoveComponent(index)}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <ColoredLine className={classes.sectionBreak} />
    </>
  );
};

TaxParent.defaultProps = {
  taxComponents: []
};

TaxParent.propTypes = {
  taxID: PropTypes.number,
  taxName: PropTypes.string,
  reverse: PropTypes.bool,
  discountAfter: PropTypes.bool,
  compound: PropTypes.bool,
  taxComponents: PropTypes.arrayOf(
    PropTypes.shape({
      order: PropTypes.number,
      percentage: PropTypes.number,
      description: PropTypes.string
    })
  )
};

export default TaxParent;
